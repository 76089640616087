// eslint-disable-next-line no-redeclare,no-unused-vars
function addToHistory(param) {
  if (!param.title) return;

  // check double point
  if (historyStack.length) {
    var i = arraySearchAssoc(historyStack, 'ident', param.ident);
    if (i !== false) {
      historyStack.splice(i, 1);
      menuHistory.find('[data-history=' + param.ident + ']').remove();
    }
  }

  // add to history
  var add = {};
  add.ident = param.ident;
  add.type = param.type;
  add.object = param.object;
  add.title = param.title;
  if (param.objectSub) add.objectSub = param.objectSub;
  if (param.id) add.id = param.id;
  historyStack.push(add);
  if (historyStack.length > 50) historyStack.shift();
  localStorage.setItem('historyStack', JSON.stringify(historyStack));

  // add point
  addHistoryToMenu(add);
  if (menuHistory) menuHistory.parent()[0].scrollTop = 0;

  // add to browser history
  if (!param.fromState) {
    // var url = param.object;
    // if (param.objectSub) url += '+' + param.objectSub;
    // if (param.id) url += ',' + param.id;
    // window.history.pushState(param.ident, param.title, url);
    window.history.pushState(param.ident, param.title);
  }
}
